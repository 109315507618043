import React from "react";
import Logo_f from "../../assets/images/logo_f.png";
const Footer = () => {
  return (
    <footer className="footer fixed-bottom">
      <div className="container">
        <div className="top_ftr">
          <div className="row justify-content-center">
            <div className="d-flex flex-column align-items-center">
              <img
                className="ff text-center mb-2"
                src={Logo_f}
                alt=""
                srcSet=""
              />
              <div class="contenttf text-center">
                <p class="text-white">
                  <i class="fa-solid fa-location-dot text-white"></i>
                  1905 Sherman Street Ste 200 1446
                  {/* <!-- </p> --> */}
                  {/* <!-- <p class="text-white"> --> */}
                  Denver, CO 80203 United States
                </p>
                <p class="text-white">
                  <a href="tel:+18447800082">
                    <i class="fa-solid fa-phone text-white"></i>
                    +1 (844) 780-0082
                  </a>
                </p>
                <p class="text-white">
                  <a
                    class="text-decoration-none text-white"
                    href="mailto:info@stopoverpayingfees.com"
                  >
                    <i class="fas fa-envelope me-2"></i>
                    info@stopoverpayingfees.com
                  </a>
                </p>
                <p class="">
                  © 2024 |
                  <a class="text-white" href="/privacy_policy.html">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
