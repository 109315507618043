import logo from "./logo.svg";
import "./App.css";
import Form from "./Components/Form";
import SavingEst from './Components/SavingEst';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RedirectToHtml from "./Components/Home";
import ThankYouPage from './Components/ThankYouPage';
import NewHome from "./pages/NewHome";

const reload = () => window.location.reload();

function App() {
  const leadCapturForm = localStorage.getItem('leadCapturForm');
  const savingEstimatorForm = localStorage.getItem('savingEstimatorForm');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RedirectToHtml />} />
        <Route
          path="/form"
          element={
       
              <Form />
          
          }
        />
        <Route
          path="/get-free-audit-analysis"
          element={
           leadCapturForm ? (
              <SavingEst />
            ) : (
              <Navigate to="/form" replace />
            )
          }
        />

        <Route
          path="/thankyou"
          element={
            leadCapturForm && savingEstimatorForm ? (
              <ThankYouPage />
            ) : (
              <RedirectToHtml />
            )
          }
        />
        <Route path="/thankyou" element={<ThankYouPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
